/* The following block can be included in a custom.scss */

/* make the customizations */
// $grid-breakpoints: (
//     xs: 0,
//     sm: 300px,
//     md: 800px,
//     lg: 1025px,
//     xl: 1280px,
// );

$primary-color: #6fcf97;
$theme-colors: (
    'primary': $primary-color,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
