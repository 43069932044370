@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import 'styles/theme';

* {
    font-family: 'Branding', sans-serif;

    p,
    table {
        font-size: 14px !important;
        color: #707070;
    }

    .btn-primary {
        font-weight: bold;
        color: #ffffff !important;
    }

    .btn {
        height: 3rem;
        font-size: 0.75rem;
    }

    .btn-outline-primary:hover {
        color: #ffffff;
    }

    label {
        font-weight: bolder;
        color: #707070;
    }

    a th,
    h3,
    h4,
    h5 {
        color: #707070;
    }

    td {
        vertical-align: middle !important;
    }

    strong {
        color: #383838;
    }

    button {
        height: 3rem;
    }

    .btn {
        font-weight: bold;
        height: 3rem;
        font-size: 0.75rem;
    }

    .form-label {
        font-size: 0.75rem;
    }

    .form-control {
        height: 48px;
        font-size: 0.75rem;
        color: #707070;
    }

    .form-check-label {
        font-size: 0.75rem;
        color: #707070;
    }

    .form-control::-webkit-input-placeholder {
        font-weight: normal;
        font-size: 0.75rem;
        color: #e0e0e0;
    }

    label {
        font-weight: bolder;
        color: #707070;
    }

    a th,
    h3,
    h4,
    h5 {
        color: #707070;
    }

    strong {
        color: #383838;
    }

    .dropdown-item {
        color: #707070;
    }
}

@font-face {
    font-family: 'Branding';
    src: local('Branding'),
        url('./fonts/Latinotype\ -\ Branding-Medium.otf') format('truetype');
    font-weight: normal;
}

.tab-disabled-nexen {
    background: #eee!important;
    color: #666!important;
}

.navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
}

.btn-nexen:focus,
.btn-nexen:active,
.btn-nexen.active,
.btn-nexen.focus .open>.dropdown-toggle.btn-nexen {
    color: #ffffff !important;
    background-color: #7800E1 !important;
    border-color: #7800E1 !important;
    /*set the color you want here*/
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #7800E1;
    outline: 0 none;
}

.btn-nexen::before,
.btn-nexen::after,
.btn-nexen:hover {
    color: #ffffff !important;
    background-color: #7800E1 !important;
    border-color: #7800E1 !important;
    /*set the color you want here*/
    outline: 0 none;
}

.btn-nexen-outlined:focus,
.btn-nexen-outlined:active,
.btn-nexen-outlined.active,
.btn-nexen-outlined::after,
.btn-nexen-outlined::before,
.btn-nexen-outlined::after,
.open>.dropdown-toggle.btn-nexen-outlined {
    background-color: #fff !important;
    border-color: #7800E1 !important;
    /*set the color you want here*/
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #7800E1 !important;
    outline: 0 none !important;
}

.btn-nexen-outlined:hover {
    color: #ffffff !important;
    background-color: #7800E1 !important;
    border-color: #7800E1 !important;
    /*set the color you want here*/
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #7800E1 !important;
    outline: 0 none !important;
}

.breadcrumb-item>a {
    color: #707070;
}

.nexen-form-control {
    border: 1px solid #707070 !important;
    border-radius: 0 10px 10px 0 !important;
}

.nexen-form-control::placeholder {
    color: #383838;
}

.nexen-form-control:active,
.nexen-form-control:focus {
    border-color: #7800E1 !important;
    box-shadow: none !important;
}

.vertical-center {
    min-height: 100%;
    /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh;
    /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #6fcf97;
    font-weight: bold;
    border-color: #ffffff !important;
    border-bottom-color: #e0e0e0 !important;
}

.nav-tabs .nav-link {
    color: #707070;
}

.modal-title {
    color: #707070;
    font-weight: 600;
}

.input-group-text {
    background-color: #ffffff;
    border-color: #f2f2f2;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-left-color: #ffffff;
    border-color: #f2f2f2;
}

/**
 * Main wrapper
 */
.select-search {
    width: 100%;
    border-color: green;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #7800e1;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #7800e1;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #7800e1;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

/*
 * Sweet alert
 */

.swal-button--confirm {
    background-color: #7800e1;
}

.swal-button--confirm:hover {
    background-color: #7800e1 !important;
    border-color: #7800e1 !important;
}

.swal-text,
.swal-footer {
    text-align: center !important;
}

.highlight-term {
    background-color: yellow;
}

.light-scrollbar {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.light-scrollbar::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px #E0E0E0;
    background-color: #F5F5F5;
    border-radius: 10px;
}

.light-scrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.light-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #E0E0E0;
}

/*
 * Toastify
 */

.Toastify__toast--success {
    background-color: #6FCF97;
}

.Toastify__toast--error {
    background-color: #EB5757;
}

.Toastify__toast--info {
    background-color: #2D9CDB;
}

.Toastify__toast--warning {
    background-color: #F2C94C;
}

.header {
    background-color: #ffffff !important;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
}

.avatar-border {
    border: 2px solid #6fcf97
}

.navbar-nav .btn.btn-primary,
.navbar-nav .btn.btn-primary:focus,
.navbar-nav .btn.btn-primary:active {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
}

.superscript {
    position: relative;
    top: -10em;
    font-size: 10%;
}

.item {
    position: relative;
    padding-top: 20px;
    display: inline-block;
}

.notify-badge {
    border-radius: 100%;
    font-size: 12px !important;
    color: #ffffff;
}

.react-datepicker-wrapper{
    display: block !important;
}
